import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenBar from '../../../components/GreenBar';
import Certification from './Certification';
import { Container, HorizontalContainer, Title } from './certifications.styles';

const Certifications = () => {
    const { t } = useTranslation();

    const certifications = [`cert1`, `cert2`, `cert3`, `cert4`, `cert5`]

    return (
        <Container>
            <Title>{t(`aboutUs.certifications`)}</Title>
            <GreenBar style={{
                width: `185px`,
                height: `11px`,
                alignSelf: `center`,
                marginTop: `26px`
            }} />
            <HorizontalContainer>
                {certifications.map((item, i) => (
                    <Certification key={i} item={item} />
                ))}
            </HorizontalContainer>
        </Container>
    )
};

export default Certifications;
