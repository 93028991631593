import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 111px;
`;

export const Title = styled.p`
    text-align: center;
    font: normal normal bold 56px/42px Reddit Sans;
    letter-spacing: 1.4px;
    color: #182422;
    opacity: 1;
    margin: 0;
`;

export const HorizontalContainer= styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 87px;
    justify-content: space-around;
`;