import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import CustomButton from '../../components/CustomButton';
import FloatingSection from './FloatingSection';
import {
    Container,
    BgImage,
    Row,
    Col,
    Logo,
    Description,
    CloudImg,
    WhatsAppButton,
    WhatsAppLogo } from './hero.styles';
import background from '../../assets/hero/background.png';
import logo from '../../assets/hero/logo.png';
import cloud from '../../assets/hero/cloud.png';
import whatsAppLogo from '../../assets/hero/whatsapp.png';

const Hero = () => {
    const { t } = useTranslation();
    
    // TODO: Add contact functionality
    const contact = () => {
        console.log(`clicked`);
    };

    // TODO: Add functionality to WhatsApp button
    const sendWhatsApp = () => {
        console.log(`send whatsapp`);
    }

    return (
        <Container className='relative h-auto pb-2'>
            <BgImage src={background} />
            <Row className='flex flex-col lg:flex-row h-auto'>
                <Col className=''>
                    <Logo className='w-3/4 md:w-[600px] mb-4 md:mb-0' src={logo} />
                    <Description className='w-3/4 md:w-[600px] mb-4 md:mb-0'>
                        {t(`hero.description`)}
                    </Description>
                    <Link to={t(`routes.contact`)} smooth={true} duration={500}>
                        <CustomButton onClick={contact} label={t(`hero.buttonText`)} />
                    </Link>
                </Col>
                <CloudImg className='w-3/4 md:w-[552px] mt-4 md:mt-0' src={cloud} />
            </Row>
            <FloatingSection/>
            <WhatsAppButton className='bottom-5 right-5 md:bottom-12 md:right-12' onClick={sendWhatsApp}>
                <WhatsAppLogo className='h-12 md:h-20' src={whatsAppLogo} />
            </WhatsAppButton>
        </Container>
    )
};

export default Hero;