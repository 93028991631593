import React from 'react';
import { ButtonContainer, ButtonLabel } from './customButton.styles';

const CustomButton = ({label, onClick, customStyles, className}) => {
    return (
        <ButtonContainer className={className} style={{...customStyles}} onClick={() => {onClick();}}>
            <ButtonLabel>{label}</ButtonLabel>
        </ButtonContainer>
    )
}

export default CustomButton;
