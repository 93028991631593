import styled from 'styled-components';

export const Container = styled.div`
    height: fit-content;
    min-height: 280px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 30px #00000008;
    border-radius: 75px;
    opacity: 1;
    z-index: 1;
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 268px;
`;

export const SectionIcon = styled.img`
    height: 56px;
`;

export const SectionLabel = styled.p`
    margin: 0;
    color: #2B403C;
    text-align: center;
    font: normal normal bold 26px/42px Reddit Sans;
    letter-spacing: 0px;
`;

