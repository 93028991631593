import React from 'react';
import { useTranslation } from 'react-i18next';
import SolutionCard from './SolutionCard';
import dictionaryES from '../../../locales/es.json';
import dictionaryEN from '../../../locales/en.json';

const SolutionsCarousel = () => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const { solutions } = lang === `es`
        ? dictionaryES.translation.portfolio
        : dictionaryEN.translation.portfolio;

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 auto-rows-[1fr]'>
            {solutions.map((sol, i) => (
                <SolutionCard data={sol} key={i} />
            ))}
        </div>
    )
};

export default SolutionsCarousel;
