import React from 'react';
import { useTranslation } from 'react-i18next';
import SolutionsCarousel from './SolutionsCarousel';
import GreenBar from '../../components/GreenBar';
import { Container, Title } from './portfolio.styles';

const Portfolio = () => {
    const { t } = useTranslation();

    return (
        <Container className='pt-[800px] md:pt-[200px]' id={t(`routes.portfolio`)}>
            <Title>{t(`portfolio.title`)}</Title>
            <GreenBar style={{
                width: "185px",
                height: "11px",
                alignSelf: "center",
                marginBottom: "40px"
            }} />
            <SolutionsCarousel />
        </Container>
    );
};

export default Portfolio;