import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 60px;
    background: rgb(34,49,47) linear-gradient(90deg, rgba(34,49,47,1) 0%, rgba(34,71,65,1) 100%);
    opacity: 1;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 10;
`;

export const CollapsibleMenuContainer = styled.div`
    background: rgb(34,49,47) linear-gradient(90deg, rgba(34,49,47,1) 0%, rgba(34,71,65,1) 100%);
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    zIndex: 1000;
    padding-bottom: 20px;
`;

export const Logo = styled.img`
    height: 40px;
`;

export const MenuIcon = styled.img`
    height: 40px;
`;

export const LogoContainer = styled.div``;
export const OptsContainer = styled.div``;
export const MenuIconContainer = styled.div``;
