import React from 'react';
import Description from './Description';
import MissionVission from './MissionVission';
import Certifications from './Certifications';
import Banner from './Banner';
import Partners from './Partners';
import { Container } from './aboutUs.styles';

const AboutUs = () => (
    <Container>
        <Description />
        <MissionVission />
        <Certifications />
        <Banner />
        <Partners />
    </Container>
);

export default AboutUs;