import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, SectionContainer, SectionIcon, SectionLabel } from './floatingSection.styles';
import agility from '../../../assets/hero/bullets/agility.png';
import support from '../../../assets/hero/bullets/support.png';
import service from '../../../assets/hero/bullets/service.png';
import costs from '../../../assets/hero/bullets/costs.png';

const Section = ({icon, label}) => (
    <SectionContainer className='mb-5 md:mb-0'>
        <SectionIcon src={icon} />
        <SectionLabel>{label}</SectionLabel>
    </SectionContainer>
);

const FloatingSection = () => {
    const { t } = useTranslation();
    return (
        <Container className='
            d-flex
            flex-wrap
            justify-around
            items-center
            w-full
            md:w-5/6
            absolute
            bottom-0
            transform
            sm:translate-y-[150px]
            translate-y-[738px]
            py-5
            md:p-0'>
            <Section icon={agility} label={t(`hero.floating.agility`)} />
            <Section icon={support} label={t(`hero.floating.support`)} />
            <Section icon={service} label={t(`hero.floating.service`)} />
            <Section icon={costs} label={t(`hero.floating.costs`)} />
        </Container>
    );
};

export default FloatingSection;
