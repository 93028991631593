import styled from 'styled-components';

export const ButtonContainer = styled.button`
    width: fit-content;
    border-radius: 40px;
    border: 3px solid #00F191;
    padding: 22px 46px 22px 46px;
    background-color: transparent;
    cursor: pointer;
`;

export const ButtonLabel = styled.p`
    color: #08f291;
    text-align: center;
    font: normal normal bold 28px/42px Reddit Sans;
    letter-spacing: 0.7px;
    margin: 0;
`;
