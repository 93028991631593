import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 310px;
    min-height: 610px;
    padding: 56px;
    border: 1px solid #D9D9D9;
    border-radius: 80px;
`;

export const Icon = styled.img`
    width: 104px;
    height: 104px;
`;

export const Title = styled.p`
    margin: 25px 0px 0px 0px;
    color: #182422;
    text-align: left;
    font: normal normal bold 24px/42px Reddit Sans;
    letter-spacing: 0.6px;
`;

export const ListContainer = styled.ul`
    margin-top: 64px;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
`;

export const ListItem = styled.li`
    color: #182422;
    text-align: left;
    font: normal normal normal 16px/32px Reddit Sans;
    letter-spacing: 0px;
`;
