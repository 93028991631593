import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 138px;
    align-items: center;
`;

export const AboutUsImage = styled.img`
    width: 552px;
    background-color: #CBF8E5;
    border-radius: 100%;
    overflow: visible;
`;