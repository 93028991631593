import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/CustomButton';
import ContactHeader from './ContactHeader';
import {Container, BgImage, TextInput, TextArea, FormContainer} from './contact.styles';
import background from '../../assets/contact/background.png'

const Contact = () => {
    const [name, setName] = useState(``);
    const [email, setEmail] = useState(``);
    const [msg, setMsg] = useState(``);

    const { t } = useTranslation();

    const onChangeName = (e) => {
        setName(e.target.value);
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onChangeMsg = (e) => {
        setMsg(e.target.value);
    };

    // TODO: Add contact function
    const contact = () => {
        console.log(msg);
    }

    return (
        <Container>
            <BgImage src={background}/>
            <ContactHeader />
            <FormContainer>
                <TextInput
                    className='w-11/12 md:w-[658px]'
                    type='text'
                    value={name}
                    placeholder={t(`contact.form.name`)}
                    maxLength='100'
                    onChange={onChangeName}/>
                <TextInput
                    className='w-11/12 md:w-[658px]'
                    type='email'
                    value={email}
                    placeholder={t(`contact.form.email`)}
                    maxLength='100'
                    onChange={onChangeEmail}/>
                <TextArea 
                    className='w-11/12 md:w-[658px]'
                    value={msg}
                    placeholder={t(`contact.form.msg`)}
                    onChange={onChangeMsg}/>
                <CustomButton
                    onClick={contact}
                    label={t(`contact.form.button`)}
                    className="self-center md:self-end"
                    customStyles={{
                        paddingLeft: `88px`,
                        paddingRight: `88px`,
                        marginTop: `56px`
                    }} />
            </FormContainer>
        </Container>
    )
};

export default Contact;