import styled from 'styled-components';

export const Container = styled.div`
    height: fit-content;
    padding: 64px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 30px #0000000A;
    border-radius: 12px;
    opacity: 1;
    margin: 0;
`;

export const Title = styled.p`
    text-align: left;
    font: normal normal bold 56px/64px Reddit Sans;
    letter-spacing: 1.4px;
    color: #182422;
    opacity: 1;
    margin: 0;
`;

export const Subtitle = styled.p`
    text-align: left;
    font: normal normal medium 24px/42px Reddit Sans;
    letter-spacing: 0.6px;
    color: #D9D9D9;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
`;

export const Text = styled.p`
    max-width: 456px;
    text-align: left;
    font: normal normal medium 16px/32px Reddit Sans;
    letter-spacing: 0px;
    color: #182422;
    opacity: 1;
    margin: 0;
`;