import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    height: 990px;;
    background: transparent linear-gradient(117deg, #182422 0%, #173933 100%) 0% 0% no-repeat padding-box;
    align-items: center;
    justify-content: center;
    margin-top: 136px;
`;

export const BgImage = styled.img`
    height: 757px;
    width: 823px;
    position: absolute;
    top: 95px;
    left: calc(100vw - 368px);
    z-index: 0;
    overflow-x: hidden;
    object-fit: cover;
`;

export const TextInput = styled.input`
    height: 80px;
    box-shadow: 0px 30px 30px #00000008;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    opacity: 0.24;
    display: flex;
    padding-left: 32;
    background-color: transparent;
    text-align: left;
    font: normal normal normal 28px/42px Reddit Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.5;
    padding-left: 32px;
    margin-top: 32px;
`;

export const TextArea = styled.textarea`
    height: 160px;
    box-shadow: 0px 30px 30px #00000008;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    opacity: 0.24;
    display: flex;
    padding-left: 32px;
    background-color: transparent;
    text-align: left;
    font: normal normal normal 28px/42px Reddit Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.5;
    padding-left: 32px;
    padding-top: 32px;
    margin-top: 32px;
    resize: none;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
`;