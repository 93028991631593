import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenBar from '../../../../components/GreenBar';
import { Container, Title, Subtitle, Text } from './aboutUsCard.styles';

const AboutUsCard = () => {
    const { t } = useTranslation();

    return (
        <Container className='w-full md:w-[750px] lg:w-[796px]'>
            <Subtitle>{t(`aboutUs.card.subtitle`)}</Subtitle>
            <Title>{t(`aboutUs.card.title`)}</Title>
            <GreenBar style={{
                width: `74px`,
                height: `11px`,
                marginTop: `24px`,
                marginBottom: `32px`
            }} />
            <Text>{t(`aboutUs.card.text`)}</Text>
        </Container>
    )
};

export default AboutUsCard;
