import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Option from './Option';
import { Container, LogoContainer, Logo, OptsContainer, MenuIconContainer, MenuIcon, CollapsibleMenuContainer } from './navbar.styles';
import logo from '../../assets/navbar/logo.png';
import Menu from '../../assets/navbar/menu.png';
import Close from '../../assets/navbar/close.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { t, i18n } = useTranslation();

    const options = [
        {
            label: t(`menu.portfolio`),
            route: t(`routes.portfolio`)
        },
        {
            label: t(`menu.aboutUs`),
            route: t(`routes.aboutUs`)
        },
        {
            label: t(`menu.contact`),
            route: t(`routes.contact`)
        }
    ];

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const changeLang = () => {
        if (i18n.resolvedLanguage === `es`) {
            i18n.changeLanguage(`en`);
        } else {
            i18n.changeLanguage(`es`);
        }
    };

    return (
        <Fragment>
            <Container className='w-full pl-8 pr-8'>
                <LogoContainer>
                    <Logo className='md:pl-8' src={logo} />
                </LogoContainer>
                <OptsContainer className='hidden md:flex space-x-20'>
                    {options.map((opt, i) => (
                        <Option key={i} label={opt.label} isLink section={opt.route} />
                    ))}
                    <Option
                        key="lang"
                        label={i18n.resolvedLanguage === `es` ? `English` : `Español`}
                        onClick={changeLang} />
                </OptsContainer>
                <MenuIconContainer className='md:hidden flex items-center' onClick={toggleMenu}>
                    <MenuIcon src={isOpen ? Close : Menu} />
                </MenuIconContainer>
            </Container>
            {isOpen && (
                <CollapsibleMenuContainer>
                    {options.map((opt, i) => (
                        <Option className='block p-2' key={i} label={opt.label} onClick={() => setIsOpen(false)} isLink section={opt.route} />
                    ))}
                    <Option
                        key="lang"
                        label={i18n.resolvedLanguage === `es` ? `English` : `Español`}
                        onClick={changeLang} />
                </CollapsibleMenuContainer>
            )}
        </Fragment>
    )
}

export default Navbar;
