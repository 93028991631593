import React, {useState, useEffect} from 'react';
import { Container, Icon, Title, ListContainer, ListItem } from './solutionCard.styles';

const SolutionCard = ({data}) => {
    const [icon, setIcon] = useState();

    const loadImage = (imageName) => {
        import (`../../../../assets/portfolio/${imageName}`)
            .then(image => {
                setIcon(image.default)
            });
    };

    useEffect(() => {
        loadImage(data.iconName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="flex justify-self-center">
            {icon && <Icon src={icon} />}
            <Title>{data.title}</Title>
            <ListContainer>
                {data.list.map((item, i) => (
                    <ListItem key={i}>{`• ${item}`}</ListItem>
                ))}
            </ListContainer>
        </Container>
    )
};

export default SolutionCard;
