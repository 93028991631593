import React from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix marker icon issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapComponent = () => {
    const { t } = useTranslation();
    return (
        <a href='https://maps.app.goo.gl/JeGNMTfjKutL8rwo8' target="_blank" rel="noreferrer">
            <MapContainer
            center={[20.694097, -103.375135]}
            zoom={18}
            minZoom={18}
            maxZoom={18}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            zoomControl={false}
            dragging={false} 
            touchZoom={false} 
            keyboard={false} 
            style={{ height: "515px", width: "100%" }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[20.694097, -103.375135]}>
                    <Tooltip permanent direction="top">
                        <span>{t(`map.marker`)}</span>
                    </Tooltip>
                </Marker>
            </MapContainer>
        </a>
    );
}

export default MapComponent;