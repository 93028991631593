import React from 'react';
import AboutUsCard from './AboutUsCard';
import { Container, AboutUsImage } from './description.styles';
import ImageAboutUs from '../../../assets/aboutUs/AboutUs.png';

const Description = () => {
    return (
        <Container className='flex-col lg:flex-row h-auto'>
            <AboutUsCard />
            <AboutUsImage src={ImageAboutUs} />
        </Container>
    )
};

export default Description;
