import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ES from './locales/es.json';
import EN from './locales/en.json';

const resources = {
    en: EN,
    es: ES
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: `es`,
        supportedLngs: [`es`, `en`],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;