import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Logo, PrivacyLink, Rights, OptionContainer, OptionIcon, OptionLabel } from './footer.styles';
import logo from '../../assets/footer/logo.png';
import mailIcon from '../../assets/footer/mailIcon.png';
import phoneIcon from '../../assets/footer/phoneIcon.png';
import mapIcon from '../../assets/footer/mapIcon.png';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Container className='gap-5 p-4 md:gap-[211px] md:p-0'>
            <Col>
                <Logo src={logo} />
                <PrivacyLink>{t(`footer.privacy`)}</PrivacyLink>
                <Rights>{t(`footer.rights`)}</Rights>
            </Col>
            <Col>
                <OptionContainer>
                    <OptionIcon src={mailIcon} />
                    <a href={`mailto:${t(`footer.mail`)}`} style={{textDecoration: `none`}}>
                        <OptionLabel>{t(`footer.mail`)}</OptionLabel>
                    </a>
                </OptionContainer>
                <OptionContainer>
                    <OptionIcon src={phoneIcon} />
                    <a href={`tel:${t(`footer.phone`)}`} style={{textDecoration: `none`}}>
                        <OptionLabel>{t(`footer.phone`)}</OptionLabel>
                    </a>
                </OptionContainer>
                <OptionContainer>
                    <OptionIcon src={mapIcon} />
                    <a href='https://maps.app.goo.gl/JeGNMTfjKutL8rwo8' style={{textDecoration: `none`}} target="_blank" rel="noreferrer">
                        <OptionLabel>{t(`footer.address`)}</OptionLabel>
                    </a>
                </OptionContainer>
            </Col>
        </Container>
    )
}

export default Footer;
