import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.p`
    text-align: center;
    font: normal normal bold 56px/42px Reddit Sans;
    letter-spacing: 1.4px;
    color: #08F291;
    opacity: 1;
    margin: 0;
`;

export const MsgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
`;

export const MsgIcon = styled.img`
    width: 27px;
    height: 27px;
`;

export const Msg = styled.p`
    font: normal normal bold 40px/48px Reddit Sans;
    letter-spacing: 1px;
    color: #FFFFFF;
    margin: 0;
`;

export const Communicate = styled.p`
    font: normal normal normal 24px/48px Reddit Sans;
    letter-spacing: 0.6px;
    color: #FFFFFF;
`;
