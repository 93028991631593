import React from 'react';
import {Container, BannerImg} from './banner.styles';
import Banner1 from '../../../assets/aboutUs/banner/Banner_1.png'
import Banner2 from '../../../assets/aboutUs/banner/Banner_2.png'
import Banner3 from '../../../assets/aboutUs/banner/Banner_3.png'

const Banner = () => (
    <Container className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        <BannerImg src={Banner1} />
        <BannerImg src={Banner2} />
        <BannerImg src={Banner3} />
    </Container>
);

export default Banner;
