import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-height: 337px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #172522 0% 0% no-repeat padding-box;
    opacity: 1;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Logo = styled.img`
    width: 241px;
`;

export const PrivacyLink = styled.a`
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/42px Reddit Sans;
    letter-spacing: 0.4px;
    color: #08F291;
    opacity: 1;
`;

export const Rights = styled.small`
    text-align: left;
    font: normal normal normal 16px/42px Reddit Sans;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    opacity: 1;
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
`;

export const OptionIcon = styled.img`
    height: 19px;
`;

export const OptionLabel = styled.p`
    text-align: left;
    font: normal normal normal 18px/42px Reddit Sans;
    letter-spacing: 0.45px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0;
`;
