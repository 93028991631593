import './App.css';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Hero from './pages/Hero';
import Portfolio from './pages/Portfolio';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import MapComponent from './components/MapComponent';
import Footer from './components/Footer';

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Element name={t(`routes.portfolio`)}>
        <Portfolio />
      </Element>
      <Element name={t(`routes.aboutUs`)}>
        <AboutUs />
      </Element>
      <Element name={t(`routes.contact`)}>
        <Contact />
      </Element>
      <MapComponent />
      <Footer />
    </div>
  );
}

export default App;
