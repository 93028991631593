import React from 'react';
import { Link } from 'react-scroll';
import { MenuOpt } from './option.styles';

const Option = ({label, onClick, className, isLink, section}) => {
    const OptComponent = isLink ? (
        <Link to={section} smooth={true} duration={500}>
            <MenuOpt className={className} onClick={onClick}>{label}</MenuOpt>    
        </Link>
    ) : (
        <MenuOpt className={className} onClick={onClick}>{label}</MenuOpt>
    );
    return OptComponent
}

export default Option;