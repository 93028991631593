import styled from 'styled-components';

export const MenuOptContainer = styled.div`
`;

export const MenuOpt = styled.a`
    text-align: center;
    font: normal normal normal 20px/40px Reddit Sans;
    letter-spacing: 0.6px;
    color: white;
    transition: color 0.3s ease, text-decoration-color 0.3s ease, text-underline-offset 0.3s ease;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: #3CF291;
        text-decoration: underline;
        text-decoration-color: #3CF291;
        text-decoration-thickness: 3px;
        text-underline-offset: 15px;
    }
`;