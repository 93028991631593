import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenBar from '../../../components/GreenBar';
import Partner from './Partner';
import {Container, Title, HorizontalContainer} from './partners.styles';
import nutanix from '../../../assets/aboutUs/partners/nutanix.png';
import cisco from '../../../assets/aboutUs/partners/cisco.png';
import fortinet from '../../../assets/aboutUs/partners/fortinet.png';
import lenovo from '../../../assets/aboutUs/partners/lenovo.png';
import veeam from '../../../assets/aboutUs/partners/veeam.png';
import vmware from '../../../assets/aboutUs/partners/vmware.png';
import apc from '../../../assets/aboutUs/partners/apc.png';
import huawei from '../../../assets/aboutUs/partners/huawei.png';

const Partners = () => {
    const {t} = useTranslation();

    const partners = [
        {alt: `nutanix`, src: nutanix},
        {alt: `cisco`, src: cisco},
        {alt: `fortinet`, src: fortinet},
        {alt: `lenovo`, src: lenovo},
        {alt: `veeam`, src: veeam},
        {alt: `vmware`, src: vmware},
        {alt: `apc`, src: apc},
        {alt: `huawei`, src: huawei},
    ]

    return (
        <Container>
            <Title>{t(`aboutUs.partners`)}</Title>
            <GreenBar style={{
                width: `185px`,
                height: `11px`,
                alignSelf: `center`,
                marginTop: `26px`
            }} />
            <HorizontalContainer>
                {partners.map((item, i) => (
                    <Partner key={i} item={item} />
                ))}
            </HorizontalContainer>
        </Container>
    )
};

export default Partners;
