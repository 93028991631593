import React from 'react';

const GreenBar = ({style}) => (
    <div
        style={{
            ...style,
            display: `flex`,
            backgroundColor: `#08F291`
        }}
    />
);

export default GreenBar;
