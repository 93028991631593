import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoCard from './InfoCard';
import { Container } from './missionVission.styles';
import dictionaryES from '../../../locales/es.json';
import dictionaryEN from '../../../locales/en.json';

const MissionVission = () => {
    const {i18n} = useTranslation();
    const lang = i18n.language;
    const {cards} = lang === `es`
        ? dictionaryES.translation.aboutUs
        : dictionaryEN.translation.aboutUs;

    return (
        <Container>
            {cards && cards.map((card, i) => (
                <InfoCard key={i} item={card} />
            ))}
        </Container>
    )
};

export default MissionVission;
