import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-top: 151px;
`;

export const BannerImg = styled.img`
    width: 640px;
`;