import React from 'react';
import GreenBar from '../../../../components/GreenBar';
import { Container, Title, Description } from './infoCard.styles';

const InfoCard = ({item}) => {
    return (
        <Container>
            <Title>{item.title}</Title>
            <GreenBar style={{
                width: `74px`,
                height: `11px`,
                marginBottom: `32px`
            }} />
            <Description>{item.description}</Description>
        </Container>
    )
};

export default InfoCard;
