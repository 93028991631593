import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: transparent linear-gradient(116deg, #182422 0%, #173933 100%) 0% 0% no-repeat padding-box;
    align-items: center;
    justify-content: center;
    z-index: 200;
`;

export const BgImage = styled.img`
    height: 1279px;
    width: 1177px;
    position: absolute;
    object-fit: cover;
    top: -87px;
    left: -92px;
    z-index: 0;
`;

export const Row = styled.div`
    align-items: center;
    z-index: 1;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
`;

export const Logo = styled.img`
    z-index: 1;
`;

export const Description = styled.p`
    color: #FFFFFF;
    text-align: left;
    font: normal normal normal 28px/42px Reddit Sans;
    z-index: 1;
`;

export const CloudImg = styled.img`
    z-index: 1;
`;

export const WhatsAppButton = styled.button`
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: 0;
    padding: 0;
    border-radius: 100px;
    cursor: pointer;
    position: fixed;
    z-index: 100;
`;

export const WhatsAppLogo = styled.img `
`;
