import styled from 'styled-components';

export const Container = styled.div`
    width: 400px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    opacity: 1;
    padding: 64px;
`;

export const Title = styled.p`
    text-align: left;
    font: normal normal bold 48px/64px Reddit Sans;
    letter-spacing: 1.2px;
    color: #182422;
    opacity: 1;
    margin: 0;
`;

export const Description = styled.p`
    text-align: left;
    font: normal normal medium 16px/32px Reddit Sans;
    letter-spacing: 0px;
    color: #182422;
    opacity: 1;
    margin: 0;
`;