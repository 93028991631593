import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.h1`
    color: #182422;
    text-align: center;
    font: normal normal bold 56px/42px Reddit Sans;
    letter-spacing: 1.4px;
`;
