import React from 'react';
import { useTranslation } from 'react-i18next';
import  { Container, Title, MsgContainer, MsgIcon, Msg, Communicate } from './contactHeader.styles';
import msgIcon from '../../../assets/contact/msgIcon.png';

const ContactHeader = () => {
    const { t } = useTranslation();
    
    return (
        <Container>
            <Title>{t(`contact.header.title`)}</Title>
            <MsgContainer>
                <MsgIcon className='mx-4' src={msgIcon} />
                <Msg className='text-left md:text-center'>{t(`contact.header.msg`)}</Msg>
            </MsgContainer>
            <Communicate className='text-left md:text-center ml-4 md:m-0'>{t(`contact.header.msgSmall`)}</Communicate>
        </Container>
    )
};

export default ContactHeader;
